@import './../styles/variables.scss';

.container {
	height: calc(100vh - #{$headerHeight});
	background: url(../imgs/bg.webp);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
