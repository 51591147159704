@import url('https://fonts.cdnfonts.com/css/akira-expanded');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

// Fonts
$Akira: 'Akira', sans-serif;
$Kanit: 'Kanit', sans-serif;

// Colors
$dark: rgb(29, 29, 29);
$light: #fff;
$primary: rgb(162, 162, 246);
$bg: rgb(244, 244, 255);
$trak-green: rgb(0, 164, 95);
$trak-orange: rgb(214, 128, 0);
$bg-dark: rgb(2, 2, 3);

// Spacing
$spacing-md: 16px;
$spacing-lg: 32px;

// Border Radius
$borderRadius: 12px;

// Header Height:
$headerHeight: 100px;

@mixin breakpoint($point) {
	@if $point == md {
		// 768px
		@media (min-width: 48em) {
			@content;
		}
	}

	@if $point == xsm {
		// 768px
		@media (min-width: 18em) {
			@content;
		}
	}

	@if $point == sm {
		// 768px
		@media (min-width: 40em) {
			@content;
		}
	}

	@if $point == lg {
		// 768px
		@media (min-width: 64em) {
			@content;
		}
	}

	@if $point == xl {
		// 768px
		@media (min-width: 80em) {
			@content;
		}
	}

	@if $point == 2xl {
		// 768px
		@media (min-width: 96em) {
			@content;
		}
	}
}
