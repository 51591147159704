@import './variables.scss';

* {
	box-sizing: border-box;
	margin: 0;
}

body {
	width: 100%;
	font-family: 'Akira Expanded', sans-serif;
	color: $dark;
}

#root {
	height: 100%;
}
